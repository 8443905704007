.faq h3 {
    font-size: 38px;
    color: #649e93;

}

.faq p {
    font-size: 20px;
    padding: 10px 50px 50px;
    max-width: 700px;
    margin: auto;
}