.typewriter-text h2 {
    position: relative;
    font-size: 50px;
    font-family: 'Style Script', cursive;
    margin-bottom: 50px;
    color: #649e93;
    padding-bottom: 30px;
}

.typewriter-text{
    text-align: center;
}