.gang-title h3 {
    position: relative;
    font-size: 40px;
    font-family: 'Great Vibes', cursive;
    margin-bottom: 0;
    color: #649e93;
    padding-bottom: 0;
    text-align: center;
    transform: translateY(150%);
}

.mobile {
    display: none;
}

.nomobile {
    display: block;
}

@media(max-width:996px) {
    .gang-title h3 {
        transform: translateY(0);
    }

    .mobile {
        display: block;
    }

    .nomobile {
        display: none;
    }
}

.couple-item {
    padding-top: 50px;
}

.couple-img {
    overflow: hidden;
    border: 2px solid #5e9a8e;
    border-radius: 100%;
}

.couple-img:hover.couple-img img {
    transform: scale(1);
}

.couple-img img {
    border-radius: 100%;
    transform: scale(1.2);
    transition: all .3s;
}

.couple-wrap2 {
    background: #fefdf8;
}

.couple-wrap2.couple-s-3 {
    background: #f2fbf9;
}

.couple-wrap2.couple-s-3 .couple-text i {
    color: #5e9a8e;
}

.couple-content h3 {
    font-size: 21px;
    color: #5e9a8e;
    font-family: 'Muli', sans-serif;
}

.couple-content2 h4 {
    font-size: 21px;
    color: #9a8c40;
}


.couple-content p {
    padding: 20px 0 30px;
}

.couple-content2 p {
    padding: 1px 20px 9px;
}

.couple-content {
    text-align: center;
}

.couple-text {
    padding: 23px 0;
}